import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { AnimationMixer } from 'three';

let robloxBoy;
let mixer;
let gltf;
let renderer;
let camera;
let scene;
let sizes;

function initializeCharacter() {
    const canvas = document.querySelector('.character');
    const parentDiv = document.querySelector('.s4-right');

    const resizeObserver = new ResizeObserver(entries => {
        for (let entry of entries) {
            if (entry.target === parentDiv) {
                updateSizes();
            }
        }
    });

    resizeObserver.observe(parentDiv);

    // Scene
    scene = new THREE.Scene();

    // Lights
    setupLights();

    // Load Model
    loadCharacterModel();

    // Sizes
    sizes = {
        width: parentDiv.clientWidth,
        height: parentDiv.clientHeight
    };

    // Camera
    setupCamera();

    // Renderer
    setupRenderer();

    tick();

    // Event listeners
    window.addEventListener('resize', updateSizes);
}

function setupLights() {
    const ambientLight = new THREE.AmbientLight(0x999999, 1);
    scene.add(ambientLight);

    const pointLight = new THREE.PointLight(0xffffff, 1);
    pointLight.position.set(10, 10, 0);
    scene.add(pointLight);
}

function loadCharacterModel() {
    const gltfLoader = new GLTFLoader();
    gltfLoader.load('/models/robloxBoyTest.glb', (loadedGltf) => {
        gltf = loadedGltf;
        robloxBoy = gltf.scene;
        scene.add(gltf.scene);
        robloxBoy.scale.set(0.6, 0.6, 0.6);
        robloxBoy.position.set(0, 0, 0);

        mixer = new AnimationMixer(robloxBoy);
        const idleAction = mixer.clipAction(gltf.animations.find(animation => animation.name === 'idle'));
        idleAction.play();
    });
}

function setupCamera() {
    camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height);
    camera.position.set(0, 1.5, 2.5);
    camera.rotation.x = 0.08;
    scene.add(camera);
}

function setupRenderer() {
    renderer = new THREE.WebGLRenderer({
        canvas: document.querySelector('.character'),
        antialias: true
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setClearColor(0x000000, 0);
}

function updateSizes() {
    sizes.width = document.querySelector('.s4-right').clientWidth;
    sizes.height = document.querySelector('.s4-right').clientHeight;
    camera.aspect = sizes.width / sizes.height;
    camera.updateProjectionMatrix();
    renderer.setSize(sizes.width, sizes.height);
}

function tick() {
    if (robloxBoy) {
        mixer.update(0.01);
    }

    renderer.render(scene, camera);
    window.requestAnimationFrame(tick);
}

function playJumpAnimation() {
    if (robloxBoy && mixer) {
        const idleAction = mixer.clipAction(gltf.animations.find(animation => animation.name === 'idle'));
        mixer.stopAllAction();
        const jumpAction = mixer.clipAction(gltf.animations.find(animation => animation.name === 'jumping'));
        jumpAction.setLoop(THREE.LoopOnce);
        jumpAction.play();
        idleAction.play();
    }
}

export { initializeCharacter, playJumpAnimation };
