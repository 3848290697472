// ThreeJS and Third-party deps
import * as THREE from "three"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"
import { LineGeometry } from "three/examples/jsm/lines/LineGeometry"
import { LineMaterial } from "three/examples/jsm/lines/LineMaterial"
import { Line2 } from "three/examples/jsm/lines/Line2"
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'


// Core boilerplate code deps
import { createCamera, createComposer, createRenderer, runApp, getDefaultUniforms } from "./core-utils"

import { loadImage, hexToRgb } from "./common-utils"
import { loadSceneBackground, getZFromImageDataPoint, vertexShader, fragmentShader } from "./functions"
import Background from "./models/banner.png"
import HeightMap from "./models/heightmap.png"

export function initializeScene(manager) {


window.THREE = THREE


const params = {
    // general scene params
    speed: 2.5,
    dirLightColor1: 0x999999,
    dirLightColor2: 0x7f7f7f,
    // bloom params
    bloomStrength: 0,
    bloomRadius: 0.2,
    bloomThreshold: 0.5,
    // plane params
    metalness: 0.2,
    roughness: 0.7,
    meshColor: 0xffffff,
    meshEmissive: 0x434343,
    lineWidth: 0.0,
    lineColor: 0xcee4ff,
    // sun params
    topColor: 0xd97507,
    bottomColor: 0xff51c8
}
const terrainWidth = 30
const terrainHeight = 30
const lightPos1 = {
    x: 15,
    y: 1,
    z: 5
}
const lightIntensity1 = 0.85
const lightPos2 = {
    x: -15,
    y: 1,
    z: 5
}
const lightIntensity2 = 0.85
const numOfMeshSets = 6
const sunPos = {
    x: 0,
    y: 11,
    z: -100
}
const uniforms = {
    ...getDefaultUniforms(),
    color_main: { // sun's top color
    value: hexToRgb("#FF511F", true)
    },
    color_accent: { // sun's bottom color
    value: hexToRgb("#FF511F", true)
    }
}


// Create the scene
let scene = new THREE.Scene()

let renderer = createRenderer({ antialias: true, logarithmicDepthBuffer: true })


let camera = createCamera(70, 1, 120, { x: 1.6, y: -0.5, z: 18.5 })
camera.rotation.x = 0, // Caméra orientée vers le bas
camera.rotation.y = Math.PI / 5.5; // Pas de rotation horizontale
camera.rotation.z = 0; // Pas de rotation autour de l'axe Z



let bloomPass = new UnrealBloomPass(
    new THREE.Vector2(window.innerWidth, window.innerHeight),
    params.bloomStrength,
    params.bloomRadius,
    params.bloomThreshold
);
let composer = createComposer(renderer, scene, camera, (comp) => {
    comp.addPass(bloomPass)
})

window.addEventListener('resize', onWindowResize, false);

function onWindowResize() {
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();
    renderer.setSize(window.innerWidth, window.innerHeight);
    composer.setSize(window.innerWidth, window.innerHeight);
}


let app = {
    mixer: null,
    async initScene() {


    await loadSceneBackground(scene, Background)

    this.dirLight1 = new THREE.DirectionalLight(params.dirLightColor1, lightIntensity1)
    this.dirLight1.position.set(lightPos1.x, lightPos1.y, lightPos1.z)
    scene.add(this.dirLight1)
    this.dirLight2 = new THREE.DirectionalLight(params.dirLightColor2, lightIntensity2)
    this.dirLight2.position.set(lightPos2.x, lightPos2.y, lightPos2.z)
    scene.add(this.dirLight2)


    let planeGeometries = []
    let lineGeometries = []
    let geometryPositionsArray = []


    for (let i = 0; i < 2; i++) {

        let hm_image = await loadImage(HeightMap)
        var canvas = document.createElement("canvas")
        canvas.width = hm_image.width
        canvas.height = hm_image.height
        var context = canvas.getContext("2d")
        context.drawImage(hm_image, 0, 0)
        var hm_imageData = context.getImageData(0, 0, canvas.width, canvas.height)

      // Create a PlaneGeom
        let planeGeometry = new THREE.PlaneGeometry(terrainWidth, terrainHeight, terrainWidth, terrainHeight)
        let geometryPositions = planeGeometry.getAttribute("position").array
        let geometryUVs = planeGeometry.getAttribute("uv").array

        for (let index = 0; index < geometryUVs.length / 2; index++) {
        let vertexU = geometryUVs[index * 2]
        let vertexV = geometryUVs[index * 2 + 1]

        let terrainHeight = getZFromImageDataPoint(hm_imageData, (i == 0 ? vertexU : 1 - vertexU), vertexV, canvas.width, canvas.height)
        geometryPositions[index * 3 + 2] = terrainHeight
        }
      // skew the plane geometry
        const shearMtx = new THREE.Matrix4()
        shearMtx.makeShear(-0.5, 0, 0, 0, 0, 0)
        planeGeometry.applyMatrix4(shearMtx)

        planeGeometries.push(planeGeometry)
        geometryPositionsArray.push(geometryPositions)
    }

    // zip up the gaps between the 1st and 2nd plane geometries
    for (let index = 0; index <= terrainWidth; index++) {
      let bottomOffset = (terrainWidth + 1) * terrainHeight
      // 2nd geom's bottom row height should be synced with 1st geom's top
      geometryPositionsArray[1][(bottomOffset + index) * 3 + 2] = geometryPositionsArray[0][index * 3 + 2]
      // 1st geom's bottom row height should be synced with 2nd geom's top
      geometryPositionsArray[0][(bottomOffset + index) * 3 + 2] = geometryPositionsArray[1][index * 3 + 2]
    }

    // material for the plane geometry
    let meshMaterial = new THREE.MeshStandardMaterial({
        color: new THREE.Color(params.meshColor),
        emissive: new THREE.Color(params.meshEmissive),
        metalness: params.metalness,
        roughness: params.roughness,
        flatShading: true
    })


    for (let i = 0; i < 2; i++) {
        let lineGeometry = new LineGeometry()
        let linePositions = []

        for (let row = 0; row < terrainHeight; row++) {
        let isEvenRow = row % 2 == 0
        for (let col = (isEvenRow ? 0 : (terrainWidth - 1)); isEvenRow ? (col < terrainWidth) : (col >= 0); isEvenRow ? col++ : col--) {
            for (let point = (isEvenRow ? 0 : 3); isEvenRow ? (point < 4) : (point >= 0); isEvenRow ? point++ : point--) {
            let mappedIndex
            let rowOffset = row * (terrainWidth + 1)
            if (point < 2) {
                mappedIndex = rowOffset + col + point
            } else {
                mappedIndex = rowOffset + col + point + terrainWidth - 1
            }

            linePositions.push(geometryPositionsArray[i][mappedIndex * 3])
            linePositions.push(geometryPositionsArray[i][mappedIndex * 3 + 1])
            linePositions.push(geometryPositionsArray[i][mappedIndex * 3 + 2])
            }
        }
    }
    lineGeometry.setPositions(linePositions)

    lineGeometries.push(lineGeometry)
    }

    // the material for the grid lines
    let lineMaterial = new LineMaterial({
        color: params.lineColor,
        linewidth: params.lineWidth, 
        alphaToCoverage: false,
        worldUnits: true // such that line width depends on world distance
    })

    this.meshGroup = []
    this.lineGroup = []
    // create multiple sets of plane and line meshes determined by numOfMeshSets
    for (let i = 0; i < numOfMeshSets; i++) {
        // create the meshes
        let mesh = new THREE.Mesh(planeGeometries[i % 2], meshMaterial)
        let line = new Line2(lineGeometries[i % 2], lineMaterial)
        line.computeLineDistances()
        // set the correct pos and rot for both the terrain and its wireframe
        mesh.position.set(0, -1.5, -terrainHeight * i)
        mesh.rotation.x -= Math.PI / 2
        line.position.set(0, -1.5, -terrainHeight * i)
        line.rotation.x -= Math.PI / 2
        // add the meshes to the scene
        scene.add(mesh)
        scene.add(line)
        this.meshGroup.push(mesh)
        this.lineGroup.push(line)
    }

    //custom model
    let robloxBoy;


    const gltfLoader = new GLTFLoader()
    gltfLoader.load(
        '/models/robloxBoy.glb',
        (gltf) => {
            robloxBoy = gltf.scene;
            let emissiveMaterial = new THREE.MeshStandardMaterial({
                emissive: 0xffffff,
                emissiveIntensity: 0.4 
            });

            gltf.scene.traverse((child) => {
                if (child.isMesh) {
                    if (child.isMesh && child.name === "Object_2") {
                        child.material = emissiveMaterial;
                    }

                }
            });


            scene.add(gltf.scene);
            gltf.scene.scale.set(0.2, 0.2, 0.2);
            gltf.scene.position.set(0.6, -1.5, 16.5);


                this.mixer = new THREE.AnimationMixer(gltf.scene);


                let runningClip = THREE.AnimationClip.findByName(gltf.animations, "running");
                if (runningClip) {
                    let action = this.mixer.clipAction(runningClip);
                    action.play();
                } else {
                    console.warn("Animation 'running' introuvable!");
                }
        }
    )
    

    // the sun
    const sunGeom = new THREE.SphereGeometry(30, 64, 64)
    const sunMat = new THREE.ShaderMaterial({
        uniforms: uniforms,
        vertexShader: vertexShader(),
        fragmentShader: fragmentShader(),
        transparent: true
    })
    let sun = new THREE.Mesh(sunGeom, sunMat)
    sun.position.set(sunPos.x, sunPos.y, sunPos.z)
    scene.add(sun)

},

    updateScene(interval, elapsed) {
    for (let i = 0; i < numOfMeshSets; i++) {
    this.meshGroup[i].position.z -= interval * params.speed;
    this.lineGroup[i].position.z -= interval * params.speed;
    if (this.meshGroup[i].position.z <= -terrainHeight) {
        this.meshGroup[i].position.z += numOfMeshSets * terrainHeight;
        this.lineGroup[i].position.z += numOfMeshSets * terrainHeight;
    }
    }

    if (this.mixer) {
        this.mixer.update(interval);
    }
    }
}

runApp(app, scene, renderer, camera, true, uniforms, composer)


}