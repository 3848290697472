import * as THREE from 'three';

let group;
let scene;
let camera;
let renderer;

function initializeSphere() {
    // Canvas
    const canvas = document.querySelector('.roblox-boy');
    const parentDiv = document.querySelector('.title-cell');

    // Scene
    scene = new THREE.Scene();

    // Lights
    const ambientLight = new THREE.AmbientLight(0xffffff, 1);
    scene.add(ambientLight);

    // Objects
    const radius = 1;
    const widthSegments = 20;
    const heightSegments = 16;
    const color = 0xff511f;

    const sphereGeometry = new THREE.SphereGeometry(radius, widthSegments, heightSegments);
    const positionArray = sphereGeometry.attributes.position.array;
    const cylinderMaterial = new THREE.MeshBasicMaterial({ color });

    function addCylinder(point1, point2, material) {
        const direction = new THREE.Vector3().subVectors(point2, point1);
        const orientation = new THREE.Matrix4();
        orientation.lookAt(point1, point2, new THREE.Object3D().up);
        orientation.multiply(new THREE.Matrix4().set(
            1, 0, 0, 0,
            0, 0, 1, 0,
            0, -1, 0, 0,
            0, 0, 0, 1
        ));
        const edgeGeometry = new THREE.CylinderGeometry(0.008, 0.008, direction.length(), 8, 1);
        const edge = new THREE.Mesh(edgeGeometry, material);
        edge.applyMatrix4(orientation);
        edge.position.x = (point2.x + point1.x) / 2;
        edge.position.y = (point2.y + point1.y) / 2;
        edge.position.z = (point2.z + point1.z) / 2;
        return edge;
    }

    group = new THREE.Group();
    for (let i = 0; i < widthSegments + 1; i++) {
        for (let j = 0; j < heightSegments; j++) {
            const index1 = (i + j * (widthSegments + 1)) * 3;
            const index2 = (i + (j + 1) * (widthSegments + 1)) * 3;
            const startPoint = new THREE.Vector3(positionArray[index1], positionArray[index1 + 1], positionArray[index1 + 2]);
            const endPoint = new THREE.Vector3(positionArray[index2], positionArray[index2 + 1], positionArray[index2 + 2]);
            const cylinder = addCylinder(startPoint, endPoint, cylinderMaterial);
            group.add(cylinder);
        }
    }

    for (let i = 0; i < heightSegments + 1; i++) {
        for (let j = 0; j < widthSegments; j++) {
            const index1 = (j + i * (widthSegments + 1)) * 3;
            const index2 = (j + 1 + i * (widthSegments + 1)) * 3;
            const startPoint = new THREE.Vector3(positionArray[index1], positionArray[index1 + 1], positionArray[index1 + 2]);
            const endPoint = new THREE.Vector3(positionArray[index2], positionArray[index2 + 1], positionArray[index2 + 2]);
            const cylinder = addCylinder(startPoint, endPoint, cylinderMaterial);
            group.add(cylinder);
        }
    }
    scene.add(group);


    const sizes = {
        width: parentDiv.clientWidth,
        height: parentDiv.clientHeight
    };


    camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height);
    function updateCameraPosition() {
        if (window.innerWidth <= 550) {
            camera.position.z = 4.5;
        } else if (window.innerWidth <= 950) {
            camera.position.z = 2.8;
        } else {
            camera.position.z = 2.4;
        }
    }

    updateCameraPosition();  
    scene.add(camera);

    // Renderer
    renderer = new THREE.WebGLRenderer({
        canvas: canvas,
        antialias: true
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setClearColor(0x000000, 0);

    window.addEventListener('resize', () => {
        sizes.width = parentDiv.clientWidth;
        sizes.height = parentDiv.clientHeight;
        camera.aspect = sizes.width / sizes.height;
        camera.updateProjectionMatrix();
        renderer.setSize(sizes.width, sizes.height);
        updateCameraPosition();
    });


    const BASE_ROTATION_SPEED = 0.001;
    const scrollSpeedMultiplier = 0.0002;
    let scrollSpeed = 0;

    const diagonalRotationAxis = new THREE.Vector3(1, 1, 0).normalize();

    document.addEventListener('wheel', (event) => {
        scrollSpeed = Math.abs(event.deltaY) * scrollSpeedMultiplier;
    });

    const tick = () => {
        const rotationSpeed = BASE_ROTATION_SPEED + scrollSpeed;


        group.rotateOnAxis(diagonalRotationAxis, rotationSpeed);


        scrollSpeed *= 0.9;

        renderer.render(scene, camera);
        window.requestAnimationFrame(tick);
    };

    tick();
}

export { initializeSphere };
