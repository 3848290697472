import { initializeSphere } from './sphere.js';
import { initializeCharacter } from './character.js';
import { initializeScene } from './scene.js';

const manager = new THREE.LoadingManager();
const loaderElem = document.querySelector("#loader p");

manager.onStart = (url, itemsLoaded, itemsTotal) => {
    console.log('Chargement commencé: ', url);
};

manager.onLoad = () => {
    console.log('Chargement terminé');
    document.getElementById("loader").style.display = 'none';

    initializeSphere();
    initializeCharacter(); 
    initializeScene(manager);
};

manager.onProgress = (url, itemsLoaded, itemsTotal) => {
    const percent = (itemsLoaded / itemsTotal) * 100;
    loaderElem.textContent = `${percent.toFixed(0)}%`;
};

manager.onError = (url) => {
    console.log('Erreur lors du chargement: ', url);
};

// Simule le chargement en augmentant le pourcentage toutes les 30ms pour 3 secondes.
let currentProgress = 0;

function fakeLoading() {
    if (currentProgress <= 100) {
        manager.onProgress(null, currentProgress, 100);
        currentProgress++;
        setTimeout(fakeLoading, 10); // Ici, 3 secondes sont divisées par 100 pour obtenir 30ms
    } else {
        manager.onLoad();
    }
}

fakeLoading();
