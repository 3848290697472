import { gsap } from "gsap";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";




gsap.registerPlugin(ScrollToPlugin);

function calculateTotalHeight() {
    const title1 = document.querySelector('.header-title-1');
    const title2 = document.querySelector('.header-title-2');
    const subtitle1 = document.querySelector('.header-subtitle-1');
    const content1 = document.querySelector('.header-content-1');

    const totalHeight = title1.offsetHeight + title2.offsetHeight + subtitle1.offsetHeight + content1.offsetHeight;
    document.documentElement.style.setProperty('--total-height', `${totalHeight}px`);
}

calculateTotalHeight();
window.addEventListener('resize', calculateTotalHeight);





const stickySection = document.querySelector(".s2-box");
const gauge = document.querySelector(".s2-gauge");


function transform(section) {
    const offsetTop = section.parentElement.offsetTop;
    const scrollSection = section.querySelector(".s2-game-slider");

    // Obtenez les marges gauche et droite de .s2-box
    const marginLeft = parseFloat(window.getComputedStyle(section).paddingLeft);
    const marginRight = parseFloat(window.getComputedStyle(section).paddingRight);

    // Obtenez les padding-left et padding-right du body
    const bodyStyles = window.getComputedStyle(document.body);
    const paddingLeftBody = parseFloat(bodyStyles.paddingLeft);
    const paddingRightBody = parseFloat(bodyStyles.paddingRight);

    // Calculez la largeur totale du .s2-game-slider en ajoutant les marges et les paddings du body
    const totalSliderWidth = Array.from(scrollSection.children).reduce(
        (totalWidth, slide) => totalWidth + slide.offsetWidth, 0
    );

    // Ajoutez la somme des marges gauche et droite à la largeur totale
    const totalWidthWithMargins = totalSliderWidth + marginLeft + marginRight;

    let percentage = ((window.scrollY - offsetTop) / window.innerHeight) * 100;
    percentage = percentage < 0 ? 0 : percentage > 100 ? 100 : percentage;

    // Calculez le déplacement horizontal en prenant en compte la largeur totale avec les marges
    const maxTranslateX = totalWidthWithMargins - window.innerWidth + paddingLeftBody + paddingRightBody;
    const translateX = -(percentage / 100) * maxTranslateX;

    scrollSection.style.transform = `translate3d(${translateX}px, 0, 0)`;

    // Remplissage progressif de la jauge de droite à gauche
    const gaugeWidth = (percentage / 100) * 100; // 100% correspond à la largeur de la jauge
    gauge.style.width = `${gaugeWidth}%`;

    // Changement de la couleur de fond de la jauge lorsque le défilement change de direction
    if (window.scrollY > section.lastScrollY) {
        // Scroll vers le bas, jauge orange
        gauge.style.backgroundColor = "#FF511F";
    }

    // Mémorisez la position de défilement actuelle pour la prochaine itération
    section.lastScrollY = window.scrollY;
}

// Initialisez la dernière position de défilement
stickySection.lastScrollY = window.scrollY;

window.addEventListener("scroll", (e) => {
    transform(stickySection);
});




window.addEventListener('scroll', function() {
    const scrollY = window.scrollY;
    const circle = document.querySelector('.scrolling-circle');
    const section = document.querySelector('.section-4');
    const sectionHeight = section.clientHeight;
    const windowWidth = window.innerWidth;
    
    let maxSize;

    // Définition de la taille maximale en fonction de la largeur de la fenêtre
    if (windowWidth <= 850) {
        maxSize = 60;  
    } else if (windowWidth <= 1280) {
        maxSize = 100;
    } else {
        maxSize = 130;  
    }

    // Assurez-vous que maxSize ne dépasse jamais le double de la hauteur de la section
    maxSize = Math.min(maxSize, sectionHeight * 2);

    // Calcul de la nouvelle taille du cercle en fonction du défilement
    const newSize = Math.min(50 + scrollY / 5, maxSize);
    const scaleValue = scrollY / sectionHeight; // Échelle basée sur le défilement dans la section
    
    // Mettre à jour la taille et l'échelle du cercle avec transition
    circle.style.width = newSize + 'px';
    circle.style.height = newSize + 'px';
    circle.style.transform = `scale(${scaleValue})`;
});



document.addEventListener("DOMContentLoaded", function() {
    var links = document.querySelectorAll('.menu a');

    links.forEach(function(link) {
        link.addEventListener('click', function(e) {
            e.preventDefault();
            var target = this.getAttribute('href');
            var targetPosition = document.querySelector(target).offsetTop;
            window.scrollTo({
                top: targetPosition,
                behavior: 'smooth'
            });
        });
    });
});

